import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

export const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true,
});

interface ConfigSource {
    (): {
        getAuthToken: () => string | null;
        URL?: string;
        proxyURL?: string;
        onAuthError: () => void;
        setApiVersion: (version: string) => void;
    };
}

const intercept = (
    instance: AxiosInstance,
    urlKey: string,
    configSource: ConfigSource,
) => {
    instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        const { getAuthToken, ...conf } = configSource();

        const authToken = getAuthToken();
        if (authToken) {
            config.headers.Authorization = 'Bearer ' + authToken;
        }

        if (conf[urlKey]) {
            config.baseURL = conf[urlKey];
        }

        return config as InternalAxiosRequestConfig;
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const { onAuthError } = configSource();

            if (!error.response || !error.response.status) {
                return Promise.reject(error);
            }

            if (error.response.status === 401) {
                onAuthError();
                return Promise.reject(error);
            }

            return Promise.reject(error);
        },
    );
};

const start = (configSource: ConfigSource) => {
    intercept(api, 'URL', configSource);

    // retry with new csrf token if csrf token rejected
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 419) {
                await refreshCSRFToken();
                return api(error.config);
            }
            return Promise.reject(error);
        },
    );

    api.interceptors.response.use((response) => {
        const version = response.headers['x-api-version'] || null;
        if (!version) {
            return response;
        }

        const { setApiVersion } = configSource();
        setApiVersion(version);
        return response;
    });
};

type CSRFTokenResponse = {
    token: string;
};

export const refreshCSRFToken = async () => {
    const { data } = await api.get<CSRFTokenResponse>('/auth/csrf-token');
    api.defaults.headers['X-CSRF-TOKEN'] = data.token;
};

export default { start };
