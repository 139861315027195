import * as React from 'react';
import styled from 'styled-components';
import { Placeholder } from '$state/types/contexts';
import { FormGrid } from '$ui/Settings/Forms/FormGrid';
import { ChannelConfig, ChannelField, Channels } from '$state/types';
import { mix } from '$ui/Flo/util';
import { replaceUnderscoresWithSpaces } from '@/utils/str';
import { SaveButton, DeleteButton } from '$ui/Flo/AsyncButton';
import { useAppDispatch, useAppSelector } from '$state';
import * as templates from '$state/concerns/templates';
import { selectPlaceholders } from '$state/concerns/placeholders';
import { Template } from '$state/types/templates';
import { useHistory } from 'react-router';
import { spliceAtCursor, useFocusedField } from '@/utils/fields';
import { Placeholders } from '$ui/Placeholders';
import { Approval, StatusBar, Delete } from '$ui/TemplatesV2/Parts';
import { Modal } from '$ui/Flo/Modal/Modal';
import { fsm, TemplateContext } from '$ui/TemplatesV2/fsm';
import { useMachine } from '@xstate/react';
import { useId } from 'react-aria';
import { success } from '$ui/Flo/ToastV2';
import {
    Controller,
    FormProvider,
    RefCallBack,
    useForm,
    useFormContext,
} from 'react-hook-form';

interface ConnectedTemplateEditorProps {
    channel: ChannelConfig;
    template: Template;
}

export const ConnectedTemplateEditor = (
    props: ConnectedTemplateEditorProps,
) => {
    const { channel, template } = props;
    const channelName = channel.channel;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const placeholders = useAppSelector(selectPlaceholders);

    const toastId = useId();

    const [state, send] = useMachine(fsm, {
        context: {
            channel: channel.channel as
                | Channels.Email
                | Channels.SMS
                | Channels.WhatsApp,
            channelApproval: channel.approval ?? false,
        },
        actions: {
            toast: (context, event) => {
                if (
                    event.type !==
                        'done.invoke.addTemplate.save.saving:invocation[0]' &&
                    event.type !==
                        'done.invoke.addTemplate.delete.deleting:invocation[0]'
                ) {
                    return;
                }
                let message = '';
                if (
                    event.type ===
                    'done.invoke.addTemplate.save.saving:invocation[0]'
                ) {
                    message =
                        'Template ' +
                        template.name +
                        ' has been saved' +
                        (context.approval ? ' and sent for approval.' : '.');
                }

                if (
                    event.type ===
                    'done.invoke.addTemplate.delete.deleting:invocation[0]'
                ) {
                    message =
                        'Template ' + template.name + ' has been deleted.';
                }
                success({
                    id: toastId,
                    message,
                });
                onCompleted();
            },
        },
    });

    const modalOpen = state.matches('approval');
    const duplicate = state.matches('save.duplicate');
    const saving = state.matches('save.saving');
    const deleting = state.matches('delete.deleting');
    const saved = state.matches('save.end');
    const deleted = state.matches('delete.end');
    const deleteModalOpen = state.matches('delete.confirm');

    const onCompleted = () => {
        dispatch(templates.loadTemplates());
        setTimeout(() => {
            history.push(`/settings/templates/${channelName}`);
        }, 1000);
    };

    const onSubmit = (template: Template) => {
        send('save', { data: template });
    };

    const onClose = () => {
        send('back');
    };

    const onDelete = () => {
        send('delete', { data: template });
    };

    return (
        <>
            <TemplateEditor
                saving={saving}
                deleting={deleting}
                saved={saved}
                deleted={deleted}
                template={template}
                channel={channel}
                placeholders={placeholders}
                onSave={onSubmit}
                onDelete={onDelete}
                showModal={modalOpen}
                deleteModalOpen={deleteModalOpen}
                modalClose={onClose}
                duplicate={duplicate}
            />
        </>
    );
};

interface TemplateEditorProps {
    template: Template;
    channel: ChannelConfig;
    placeholders: Placeholder[];
    onSave: (template: Template) => void;
    onDelete: () => void;
    modalClose: () => void;
    showModal: boolean;
    saving: boolean;
    deleting: boolean;
    saved: boolean;
    deleted: boolean;
    deleteModalOpen: boolean;
    duplicate: boolean;
}

export const TemplateEditor = (props: TemplateEditorProps) => {
    const {
        saved,
        deleted,
        saving,
        deleting,
        template,
        channel,
        placeholders,
        showModal,
        onSave,
        onDelete,
        modalClose,
        deleteModalOpen,
        duplicate,
    } = props;
    if (!template) {
        return null;
    }
    const form = useForm<TemplateContext>({
        mode: 'all',
        defaultValues: {
            name: template.name ?? '',
            body: template.body ?? '',
            subject: template.subject ?? '',
            approval: template.approval ?? false,
            id: template.id,
        },
        reValidateMode: 'onChange',
    });

    const [placeholderSelected, setPlaceholderSelected] = React.useState<
        null | string
    >(null);

    const { ref: subjectRef } = form.register('subject');
    const { ref: bodyRef } = form.register('body');

    const refs = {
        subject: React.useRef<HTMLInputElement>(null),
        body: React.useRef<HTMLInputElement>(null),
    };

    const [focused, focusedRef, setFocus] = useFocusedField(refs);

    const onSubmit = () => {
        form.trigger();
        if (Object.keys(form.formState.errors).length !== 0) {
            return;
        }
        const templateSave: Template = {
            ...template,
            ...form.getValues(),
            status: channel.approval && template.approval ? 'PENDING' : '',
        };
        onSave(templateSave);
    };

    React.useEffect(() => {
        if (duplicate) {
            form.setError('name', {
                type: 'validate',
                message:
                    'Template Name already exists. Please choose a unique name.',
            });
        }
    }, [duplicate]);

    React.useEffect(() => {
        if (placeholderSelected) {
            insertTag(placeholderSelected);
        }
    }, [placeholderSelected]);

    const insertTag = (tag: string) => {
        const updatedFocusedName = focused ?? 'body';

        if (!updatedFocusedName) {
            return;
        }

        const updatedFocusRef = focusedRef ?? refs[updatedFocusedName];

        const spliced = spliceAtCursor(tag, updatedFocusRef);

        if (!spliced) {
            return;
        }

        form.setValue(updatedFocusedName, spliced);

        focusedRef?.current?.focus();
    };

    React.useEffect(() => {
        const { current } = refs.body;

        if (!current) {
            return;
        }

        if (current.type === 'textarea' && current.value) {
            current.style.height = 'auto';
            current.style.height = current.scrollHeight + `px`;
        }
    }, [template]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    onSubmit();
                }}
            >
                {template?.status && (
                    <StatusBar
                        status={replaceUnderscoresWithSpaces(template.status)}
                        reason={template.reason}
                    />
                )}
                <FormGrid>
                    <Controller
                        key={'name'}
                        name={'name'}
                        control={form.control}
                        rules={{
                            required: 'Template Name is required',
                            pattern: {
                                value: /^[a-zA-Z0-9_-\s]+$/,
                                message: 'Invalid Name Format',
                            },
                        }}
                        render={({ field }) => {
                            return (
                                <InputWrapper>
                                    <Label
                                        key={field.name}
                                        htmlFor={field.name}
                                    >
                                        Template Name
                                        <LabelError
                                            hasError={
                                                form.formState.errors?.name
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {
                                                form.formState.errors?.name
                                                    ?.message
                                            }
                                        </LabelError>
                                    </Label>
                                    <Input
                                        {...field}
                                        data-cy={field.name}
                                        disabled={field.disabled}
                                        placeholder="Template Name"
                                        hasError={
                                            form.formState.errors?.name
                                                ? true
                                                : false
                                        }
                                    />
                                    <small>
                                        Only Alphanumeric Characters, Spaces,
                                        Dashes and Underscores Allowed
                                    </small>
                                </InputWrapper>
                            );
                        }}
                    />

                    <FieldsWithPlaceholders
                        fields={channel.fields}
                        placeholderSelected={placeholderSelected}
                        setPlaceholderSelected={setPlaceholderSelected}
                        setFocus={setFocus}
                        placeholders={placeholders}
                        refs={refs}
                        formRefs={{
                            subject: subjectRef,
                            body: bodyRef,
                        }}
                    />

                    {showModal && (
                        <Modal closeOnOutsideClick onClose={modalClose}>
                            <Approval
                                approved={template.approval ?? false}
                                onClose={modalClose}
                                onSave={onSubmit}
                            />
                        </Modal>
                    )}
                    {deleteModalOpen && (
                        <Modal closeOnOutsideClick onClose={modalClose}>
                            <Delete onClose={modalClose} onDelete={onDelete} />
                        </Modal>
                    )}
                </FormGrid>
                <ActionBar>
                    <SaveButton
                        label="Save Template"
                        color={'primary-500'}
                        doing={saving}
                        done={saved}
                        size="med-large"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                    {template.name !== '' && (
                        <DeleteButton
                            label="Delete Template"
                            color={'primary-500'}
                            doing={deleting}
                            done={deleted}
                            size="med-large"
                            onClick={(event) => {
                                event.stopPropagation();
                                onDelete();
                            }}
                        />
                    )}
                </ActionBar>
            </form>
        </FormProvider>
    );
};

interface FieldProps {
    fields: ChannelField[];
    placeholderSelected: null | string;
    setPlaceholderSelected: (p: string) => void;
    setFocus: (name: 'body' | 'subject') => (e: React.FocusEvent) => void;
    placeholders: Placeholder[];
    refs: {
        subject: React.RefObject<HTMLInputElement>;
        body: React.RefObject<HTMLInputElement>;
    };
    formRefs: {
        subject: RefCallBack;
        body: RefCallBack;
    };
}

const FieldsWithPlaceholders = (props: FieldProps) => {
    const form = useFormContext();

    const {
        fields,
        placeholders,
        setPlaceholderSelected,
        setFocus,
        refs,
        formRefs,
    } = props;

    return fields.map((channelField) => {
        const placeholder = channelField.placeholder;
        const name = channelField.name === 'subject' ? 'subject' : 'body';

        return (
            <Controller
                key={name}
                name={name}
                control={form.control}
                rules={{
                    required: placeholder + ' is required',
                }}
                render={({ field }) => {
                    if (field.name === 'body') {
                        return (
                            <div key={field.name}>
                                <Label key={field.name} htmlFor={field.name}>
                                    {placeholder}
                                    <LabelError
                                        hasError={
                                            form.formState.errors?.body
                                                ? true
                                                : false
                                        }
                                    >
                                        {
                                            form.formState.errors?.body
                                                ?.message as string
                                        }
                                    </LabelError>
                                </Label>
                                <BodyContainer
                                    hasError={
                                        form.formState.errors?.body
                                            ? true
                                            : false
                                    }
                                >
                                    <PlaceholdersContainer>
                                        <Left>
                                            <Placeholders
                                                position={'bottom'}
                                                placeholders={placeholders}
                                                placeholderSelected={(p) => {
                                                    setPlaceholderSelected(p);
                                                }}
                                            />
                                        </Left>
                                    </PlaceholdersContainer>
                                    <TextArea
                                        {...field}
                                        ref={(e) => {
                                            formRefs.body(e);
                                            // @ts-expect-error Read-only
                                            refs.body.current = e;
                                        }}
                                        placeholder={placeholder}
                                        onFocus={setFocus(name)}
                                        rows={10}
                                    />
                                </BodyContainer>
                            </div>
                        );
                    }
                    return (
                        <InputWrapper>
                            <Label key={field.name} htmlFor={field.name}>
                                {placeholder}
                                <LabelError
                                    hasError={
                                        form.formState.errors?.subject
                                            ? true
                                            : false
                                    }
                                >
                                    {
                                        form.formState.errors?.subject
                                            ?.message as string
                                    }
                                </LabelError>
                            </Label>
                            <Input
                                {...field}
                                data-cy={field.name}
                                ref={(e: HTMLInputElement | null) => {
                                    formRefs.subject(e);
                                    // @ts-expect-error Read-only
                                    refs.subject.current = e;
                                }}
                                disabled={field.disabled}
                                onFocus={setFocus(name)}
                                placeholder={placeholder}
                                hasError={
                                    form.formState.errors?.subject
                                        ? true
                                        : false
                                }
                            />
                        </InputWrapper>
                    );
                }}
            />
        );
    });
};

const InputWrapper = styled.div`
    ${mix.gap({ size: 1 })}
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    position: relative;
    ${mix.type({ level: 'body2', bold: true })}
    font-size: 14px;
    color: var(--gray-600);
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const Input = styled.input<{ hasError?: boolean }>`
    ${mix.padding({ padding: 1.5 })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    width: 100%;
    ${({ hasError }) => hasError && `border-color: red;`}
`;

const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray-100);
    ${mix.padding({ padding: 4 })};
`;

const BodyContainer = styled.div<{ hasError?: boolean }>`
    margin-top: 0;
    background-color: #fff;
    border: 1px solid var(--gray-300);
    ${({ hasError }) => hasError && `border-color: red;`}
`;

const PlaceholdersContainer = styled.div`
    width: 100%;
    ${mix.padding({ padding: [0.5, 0.5, 0, 0.5] })};
    display: flex;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    ${mix.gap({ size: 0.5 })};
`;

const TextArea = styled.textarea`
    border: none;
    ${mix.padding({ padding: 1.5 })};
    ${mix.type({ level: 'body2' })};
    border-top: 1px solid var(--gray-200);
    width: 100%;
    resize: none;
    outline: none;
`;

const LabelError = styled.span<{ hasError?: boolean }>`
    max-width: 75%;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    text-transform: capitalize;
    ${mix.type({ level: 'body2' })};
    color: var(--error-600);
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
    ${({ hasError }) => hasError && `display: flex;`};
`;
