import * as React from 'react';
import { NameBlock } from './NameBlock';
import { EditButton } from './EditButton';
import { SelectField, TextField } from '$ui/Flo/Field';
import { GDPRStatus, Patient, PatientStatus } from '$state/types';
import styled, { css } from 'styled-components';
import { mix, scale } from '../Flo/util';
import { fn, phone } from '$utils';
import * as dentally from '$state/concerns/dentally/patients';
import { BoxSize } from '$ui/Flo/types';
import { CountryCode } from '@/utils/phone';
import { AdditionalMenuButton } from './AdditionalMenuButton';
import { ConnectedChangeStageForm } from '$ui/Patient/ChangeStage/ChangeStageForm';
import { Modal } from '$ui/Flo/Modal/Modal';
import { Button } from '$ui/Flo/Button';

export interface AttributesProps {
    details: Patient; // The patient details
    detailsChanged: (details: Patient) => void; // Callback when the patient's
    // details are changed by the
    // user
    onEdit?: (state: boolean) => void; // Callback when the user changes the
    // editing state
    state: PatientStatus; // The network state of the patient
    availableTypes: string[]; // Avaiable (tx)types to be selected
    lite?: boolean; // Whether to display address fields
    availableSources: (string | 'Unknown')[];
    referralsEnabled?: boolean;
    dentallyState: dentally.PatientState;
    country?: string;
}

export const LEFT_PADDING = 3;

type ValueFormatter = (value: string) => string;

type ValueTransformer = (value: string) => string | null;

const nullTransformer: ValueTransformer = (value: string) =>
    value == '' ? null : value;

interface TextFieldForArgs {
    label: string;
    key: string;
    formatter?: ValueFormatter;
    transformer?: ValueTransformer;
    clipboardFormatter?: ValueFormatter;
}

export const Attributes = (props: AttributesProps) => {
    const {
        details,
        detailsChanged,
        onEdit,
        state,
        availableTypes,
        lite,
        availableSources,
        referralsEnabled,
        dentallyState,
        country = 'GB',
    } = props;
    const [edits, setEdits] = React.useState(details);
    const [editing, setEditing] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        setEdits(details);
    }, [details]);

    React.useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showModal]);

    const name = (edits.first_name + ' ' + edits.last_name).trim();

    const mergeChanges = (changes: Partial<Patient & { name: string }>) => {
        if (changes.name) {
            const split = changes.name.split(' ');
            changes.first_name = split[0];
            changes.last_name = split.slice(1, split.length).join(' ');
            delete changes.name;
        }
        setEdits({ ...edits, ...changes });
    };

    const edit = (isEditing: boolean) => {
        if (onEdit) {
            onEdit(isEditing);
        }

        if (!isEditing) {
            detailsChanged(edits);
        }
        setEditing(isEditing);
    };

    if (state === PatientStatus.ERRORED_SAVING && !editing) {
        edit(true);
    }

    const labelPortion = 33;

    const textFieldFor = (args: TextFieldForArgs) => {
        const {
            label,
            key,
            formatter = fn.identity,
            transformer = fn.identity,
            clipboardFormatter = fn.identity,
        } = args;
        return (
            <TextField
                id={key}
                label={label}
                key={key}
                valueChanged={(val) =>
                    mergeChanges({ [key]: transformer(val) })
                }
                value={editing ? edits[key] : formatter(edits[key])}
                labelPortion={labelPortion}
                editable={editing}
                transformOnCopy={clipboardFormatter}
                padLeft={LEFT_PADDING}
            />
        );
    };

    return (
        <Wrapper data-cy="patient-attributes" gap={3}>
            <div>
                <NameBlockWrapper>
                    <NameBlock state={{ name }} dentallyState={dentallyState} />
                </NameBlockWrapper>
            </div>

            <PatientEditorContainer>
                <div>
                    <FieldsWrapper padding={LEFT_PADDING}>
                        {textFieldFor({
                            label: 'First Name',
                            key: 'first_name',
                        })}
                        {textFieldFor({ label: 'Last Name', key: 'last_name' })}
                        <SelectField
                            label="Type"
                            key="type"
                            valueChanged={(type) => mergeChanges({ type })}
                            value={edits['type']}
                            labelPortion={labelPortion}
                            editable={editing}
                            options={availableTypes}
                            padLeft={LEFT_PADDING}
                        />
                        <SelectField
                            label="Lead Source"
                            key="source"
                            valueChanged={(source) => mergeChanges({ source })}
                            value={edits['source']}
                            labelPortion={labelPortion}
                            editable={editing}
                            options={availableSources}
                            padLeft={LEFT_PADDING}
                        />
                        {textFieldFor({ label: 'Email', key: 'email' })}
                        {textFieldFor({
                            label: 'Phone',
                            key: 'phone',
                            formatter: (value) =>
                                phone.format(value, country as CountryCode),
                            clipboardFormatter: (value) =>
                                phone
                                    .format(value, country as CountryCode)
                                    .replace(/\s/g, ''),
                        })}
                        {!lite && (
                            <SelectField
                                label={'Marketing Consent'}
                                key={'gdpr'}
                                valueChanged={(val) =>
                                    mergeChanges({ gdpr: val as GDPRStatus })
                                }
                                value={edits['gdpr']}
                                labelPortion={labelPortion}
                                editable={editing}
                                options={
                                    ['Opted Out', 'Opted In'] as GDPRStatus[]
                                }
                                padLeft={LEFT_PADDING}
                            />
                        )}
                        {referralsEnabled &&
                            textFieldFor({
                                label: 'Referrer Email',
                                key: 'referrer_email',
                                transformer: nullTransformer,
                            })}
                        {referralsEnabled &&
                            textFieldFor({
                                label: 'Referrer Name',
                                key: 'referrer_name',
                                transformer: nullTransformer,
                            })}
                    </FieldsWrapper>
                </div>

                <Pad scalar={[0, LEFT_PADDING, LEFT_PADDING, LEFT_PADDING]}>
                    <EditButton
                        editStatus={state}
                        onEdit={() => edit(true)}
                        onSave={() => edit(false)}
                        editing={editing}
                    />
                    {state === PatientStatus.LOADED && details.stage && (
                        <AdditionalMenuButton>
                            <Button
                                id="change-stage-button"
                                mode="outline"
                                hue="grey"
                                shade="5"
                                rounded
                                border="x-small"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setShowModal(true);
                                }}
                            >
                                Change stage
                            </Button>
                        </AdditionalMenuButton>
                    )}
                </Pad>
                {showModal && (
                    <Modal
                        onClose={() => {
                            setShowModal(false);
                        }}
                        closeOnOutsideClick={true}
                    >
                        <ConnectedChangeStageForm
                            patientName={
                                details.first_name + ' ' + details.last_name
                            }
                            patientId={details.id}
                            currentStage={details.stage ?? ''}
                            onClose={() => {
                                setShowModal(false);
                            }}
                            onComplete={() => {
                                setShowModal(false);
                            }}
                        />
                    </Modal>
                )}
            </PatientEditorContainer>
        </Wrapper>
    );
};
const borderBottom = css`
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

export const Wrapper = styled.div<{ gap: number }>`
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    ${({ gap }) => mix.gap({ size: gap })};
`;

export const PatientEditorContainer = styled.div`
    flex: 1 0 auto;
`;

export const FieldsWrapper = styled.div<{ padding: number }>`
    ${({ padding }) => mix.padding({ padding: [0, padding, 0, 0] })}
`;

export const LabelContainer = styled.div`
    ${mix.padding({ padding: [LEFT_PADDING, 0, 0, 0] })};
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })}; ;
`;

export const PadLeft = styled.div<{ scalar: number }>`
    padding-left: ${({ theme, scalar }) => scale(theme, scalar)}px;
`;

export const Pad = styled.div<{ scalar: BoxSize }>`
    ${({ scalar }) => mix.padding({ padding: scalar })};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const NameBlockWrapper = styled.div`
    ${borderBottom}
`;
